import { useScroll, useTransform, motion } from 'framer-motion';
import React from 'react';


export default function Demo() {
  const {scrollYProgress, scrollY} = useScroll();
  const xstyle = useTransform(scrollYProgress, [0, 1] , [0, -600]);

  return (
    <>
    <section className="bg-dark demo">
      <motion.div className="text" style={{x : xstyle}}>
        Lorem ipsum dolor sit.
      </motion.div>
    </section>
    <section className="bg-dark demo">

    </section>
    <section className="bg-dark demo">

    </section>
    
    </>
  )
}
